import {
  Heading,
  Img,
  InlineCallOut,
  SignUpContainer,
  Text
} from './index.styles'
import React, { useEffect } from 'react'
import { getEmailFromLocationState, getNameFromLocationState, getNameParts } from 'shared/extractors'

import LoadingSpinner from 'shared/components/LoadingSpinner'
import env from 'shared/envConfig'
import { trackPageview } from 'shared/analytics'
import verifyEmailImg from 'shared/assets/verify.png'

function ThankYou (props) {
  const email = getEmailFromLocationState(props)
  const name = getNameFromLocationState(props)

  useEffect(() => {
    trackPageview()
    if (env.THANKYOU_REDIRECT_URL) window.location.href = env.THANKYOU_REDIRECT_URL
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (env.THANKYOU_REDIRECT_URL
    ? <LoadingSpinner space />
    : <SignUpContainer>
      <Img src={verifyEmailImg}></Img>
      <Heading>Verify your email.</Heading>
      <Text>
          You’re almost there {getNameParts(name).firstName}! For your
          security, we sent a confirmation email to{' '}
        <InlineCallOut>{email}</InlineCallOut>. You are required to
          verify your email before you can start using Mobile Assistant.
      </Text>
    </SignUpContainer>
  )
}

export default ThankYou
