import React, { useState, useEffect, useCallback } from 'react'
import { StyledLayout, BackButton, Backdrop } from './index.styles'
import { API } from 'aws-amplify'
import LoadingSpinner from 'shared/components/LoadingSpinner'
import SubscriptionPicker from 'shared/components/SubscriptionPicker'
import { Button } from 'shared/components/Button'
import { colors } from 'shared/colors'
import { mdiArrowLeft } from '@mdi/js'

function AccountSubscriptions (props) {
  const [subscriptions, setSubscriptions] = useState()
  const [loading, setLoading] = useState(true)

  const fetchSubscriptions = useCallback(async () => {
    setLoading(true)
    try {
      const subscriptionResults = (await API.get('TALK_IT_API', '/subscription')).data
      setSubscriptions(subscriptionResults)
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    fetchSubscriptions()
  }, [fetchSubscriptions])

  if (loading) {
    return (
      <Backdrop>
        <StyledLayout data-testid="loading-layout" center>
          <LoadingSpinner backColor={colors.BLUE} frontColor={colors.BLUE_200} size={60} />
        </StyledLayout>
      </Backdrop>
    )
  }

  return (
    <Backdrop>
      <BackButton>
        <Button iconColor={colors.BLACK} iconName={mdiArrowLeft} onClick={() => props.history.goBack()} text>Back</Button>
      </BackButton>
      <SubscriptionPicker subscriptions={subscriptions} />
    </Backdrop>
  )
}

export default AccountSubscriptions
