import React, { useState } from 'react'
import { ModalDescription } from '../Text'
import { useAuthContext } from 'shared/context/AuthProvider'
import { useModalContext } from 'shared/context/ModalProvider'
import { useNotificationContext } from 'shared/context/NotificationProvider'
import Modal from '../Modal'
import { getTalkItUser } from 'shared/extractors'
import LoadingSpinner from 'shared/components/LoadingSpinner'
import { API } from 'aws-amplify'
import { text } from 'shared/text'

const StatusBannerActionModal = ({ actionText, actionTitle, bannerText, messageId }) => {
  const [loading, setLoading] = useState(false)
  const { sendSuccessNotification, sendErrorNotification } = useNotificationContext()
  const { closeModal } = useModalContext()
  const { currentUser } = useAuthContext()
  const { cognitoId } = getTalkItUser(currentUser)

  const statusActionPost = async () => {
    try {
      setLoading(true)
      await API.post('TALK_IT_API', `/status?cognito_id=${cognitoId}`, {
        body: {
          status_message_id: `${messageId}`,
          banner_text: `${bannerText}`,
          action_source: 'web'
        }
      })
      triggerSuccess(text.GENERAL_REQUEST.CREATE.SUCCESS)
    } catch (error) {
      triggerFailure(text.GENERAL_REQUEST.CREATE.FAILED)
    }
  }

  const triggerSuccess = (title) => {
    sendSuccessNotification({ title })
    closeModal()
  }

  const triggerFailure = (title) => {
    sendErrorNotification({ title })
    setLoading(false)
  }

  const modalDialogue = {
    primary: {
      onClick: statusActionPost,
      disabled: loading,
      children: loading ? <LoadingSpinner /> : actionTitle
    },
    secondary: {
      onClick: closeModal,
      disabled: loading
    }
  }

  return (
    <Modal dialogue={modalDialogue} onClose={closeModal} title={actionTitle}>
      <ModalDescription style={{ width: '100%' }}>{actionText}</ModalDescription>
    </Modal>
  )
}

export default StatusBannerActionModal
