import React, { useState, useEffect } from 'react'
import { StyledBanner, Message, Title, IonIconLeft, IonIconRight, Action } from './index.styles'
import { LinkButton } from '../Button'
import { API } from 'aws-amplify'
import { useAuthContext } from 'shared/context/AuthProvider'
import { useModalContext } from 'shared/context/ModalProvider'
import StatusBannerActionModal from '../StatusBannerActionModal'
import { getTalkItUser } from 'shared/extractors'

// Icon Source Ref: https://ionic.io/ionicons

function StatusBanner ({ setHasActiveStatusMsg }) {
  const { currentUser } = useAuthContext()
  const { cognitoId } = getTalkItUser(currentUser)
  const { openModal } = useModalContext()
  const [bannerText, setBannerText] = useState(null)
  const [iconLeft, setIconLeft] = useState(null)
  const [iconRight, setIconRight] = useState(null)
  const [messageId, setMessageId] = useState(null)
  const [actionTitle, setActionTitle] = useState(null)
  const [actionText, setActionText] = useState(null)

  useEffect(() => {
    const loadStatusMessage = async () => {
      try {
        const response = await API.get('TALK_IT_API', `/status?cognito_id=${cognitoId}`)
        if (response.data.message) {
          setIconLeft(response.data.left_icon_id)
          setIconRight(response.data.right_icon_id)
          setBannerText(response.data.message)
          setActionTitle(response.data.action_title)
          setActionText(response.data.action_text)
          setMessageId(response.data.status_message_id)
          setHasActiveStatusMsg(true)
        }
      } catch (error) {
        return null
      }
    }
    loadStatusMessage()
  })

  if (!bannerText) {
    return null
  }

  return (
    <StyledBanner data-testid="status-banner">
      <Message>
        {iconLeft && <IonIconLeft><ion-icon name={iconLeft} /></IonIconLeft>}
        <Title>{bannerText}</Title>
        {iconRight && <IonIconRight><ion-icon name={iconRight} /></IonIconRight>}
      </Message>
      <Action>
        {actionTitle &&
          <LinkButton onClick={() => openModal(<StatusBannerActionModal actionText={actionText} actionTitle={actionTitle} bannerText={bannerText} messageId={messageId} />)} banner>
            {actionTitle}
          </LinkButton>
        }
      </Action>
    </StyledBanner>
  )
}

export default StatusBanner
