import React, { useState } from 'react'
import { ModalDescription } from '../Text'
import { Button } from '../Button'
import { useAuthContext } from 'shared/context/AuthProvider'
import { useModalContext } from 'shared/context/ModalProvider'
import { useNotificationContext } from 'shared/context/NotificationProvider'
import Modal from '../Modal'
import { getTalkItUser } from 'shared/extractors'
import LoadingSpinner from 'shared/components/LoadingSpinner'
import { API } from 'aws-amplify'
import RadioButton from 'shared/components/RadioButton'
import { text } from 'shared/text'
import { SubscriptionMenuOptions } from './index.styles'

const SubscriptionPickerModal = ({ subscriptions }) => {
  const [loading, setLoading] = useState(false)
  const [selectedSubscription, setSelectedSubscription] = useState(null)
  const [hasRequested, setHasRequested] = useState(false)
  const { closeModal } = useModalContext()
  const { sendSuccessNotification, sendErrorNotification } = useNotificationContext()
  const { currentUser } = useAuthContext()
  const { cognitoId } = getTalkItUser(currentUser)

  const requestSubscription = async () => {
    if (!loading && selectedSubscription) {
      try {
        setLoading(true)
        await API.post('TALK_IT_API', `/subscription?cognito_id=${cognitoId}`, {
          body: {
            subscription_type: `${selectedSubscription}`
          }
        })
        triggerSuccess(text.GENERAL_REQUEST.CREATE.SUCCESS)
      } catch (error) {
        triggerFailure(text.GENERAL_REQUEST.CREATE.FAILED)
      }
    }
  }

  const triggerSuccess = (title) => {
    sendSuccessNotification({ title })
    setHasRequested(true)
  }

  const triggerFailure = (title) => {
    sendErrorNotification({ title })
    setLoading(false)
  }

  const modalDialogue = {
    primary: {
      onClick: requestSubscription,
      disabled: loading || !selectedSubscription,
      children: loading ? <LoadingSpinner /> : 'Request Upgrade'
    },
    secondary: {
      onClick: closeModal,
      disabled: loading
    }
  }

  if (!hasRequested) {
    return (
      <Modal dialogue={modalDialogue} onClose={closeModal} title={'Request Upgrade'}>
        <ModalDescription style={{ width: '100%' }}>Select from the following plans to upgrade:</ModalDescription>
        <SubscriptionMenuOptions>
          {subscriptions?.length > 0 && subscriptions.map((subscription, index) =>
            <RadioButton
              isSelected={selectedSubscription === subscription.name}
              key={index}
              label={subscription.name}
              onSelected={() => setSelectedSubscription(subscription.name)}
            >
              {`$${subscription.price} / ${subscription.price_duration_type}`}
            </RadioButton>
          )}
        </SubscriptionMenuOptions>
      </Modal>
    )
  }

  return (
    <Modal onClose={closeModal} title={'Congratulations!'} >
      <ModalDescription>Thank you for subscribing to our service! While our systems are being updated, a member of our customer experience team will reach out to you to get billing details completed. Expect to hear from them in the next day or two.</ModalDescription>
      <Button onClick={closeModal} style={{ width: '100%' }}>OK</Button>
    </Modal>
  )
}

export default SubscriptionPickerModal
