import React, { useState, useEffect, useCallback } from 'react'
import { getCognitoEmailFromUser, getFullNameFromUser, getInitialsFromUser, getTalkItUser, getDictatorIdFromUser, getTalkItTeam } from 'shared/extractors'
import { useAuthContext } from 'shared/context/AuthProvider'
import { useModalContext } from 'shared/context/ModalProvider'
import { Version, LinesSection, Section, SubSection, Column, DictatorID, PhoneList, Divider, AppDownloadButtons } from './index.styles'
import { P2, H3, Caption, B1 } from 'shared/components/Text'
import { Button } from 'shared/components/Button'
import ButtonGroup from 'shared/components/ButtonGroup'
import { mdiExitToApp } from '@mdi/js'
import ListItem from 'shared/components/ListItem'
import LineUsage from 'shared/components/LineUsage'
import InfoPopup from 'shared/components/InfoPopup'
import Avatar from 'shared/components/Avatar'
import Container from 'shared/components/Container'
import ChangePasswordModal from 'shared/components/ChangePasswordModal'
import RegisterPhoneModal from 'shared/components/RegisterPhoneModal'
import { formatPhoneNumber } from 'shared/formatters'
import { colors } from 'shared/colors'
import { text } from 'shared/text'
import { API } from 'aws-amplify'
import playStore from 'shared/assets/google-play.png'
import appStore from 'shared/assets/app-store.png'
import AccountPageLayout from 'shared/components/AccountPageLayout'
import moment from 'moment'
import env from 'shared/envConfig'
import packageJson from '../../../package.json'

function AccountOverview () {
  const [phoneNumbers, setPhoneNumbers] = useState([])
  const [loading, setLoading] = useState(true)
  const { signOut, currentUser } = useAuthContext()
  const { attributes: { sub: cognitoId } = {} } = currentUser
  const { openModal } = useModalContext()
  const talkItAttributes = getTalkItUser(currentUser)
  const team = getTalkItTeam(currentUser)
  const dictatorId = getDictatorIdFromUser(currentUser)
  const { primaryUserEmail, primaryUserPhone } = getTalkItUser(currentUser)

  const fetchData = useCallback(async () => {
    setLoading(true)
    setPhoneNumbers(await fetchPhoneNumbers(cognitoId))
    setLoading(false)
  }, [cognitoId])

  const fetchPhoneNumbers = async (cognitoId) => {
    if (!cognitoId) {
      return []
    }

    try {
      const phoneList = (await API.get('TALK_IT_API', `/user_phone?cognito_id=${cognitoId}`)).data
      return phoneList
    } catch (error) {
      return []
    }
  }

  useEffect(() => {
    fetchData()
  }, [fetchData])

  function timePeriod () {
    if (team.linesInterval === 'quarter') {
      return 3
    }
    return 1
  }

  function nextInvoiceDate (timePeriod) {
    const lineResetDate = moment(team.lastLinesInvoiceOn).add(timePeriod, 'M')
    const beforeToday = lineResetDate > Date.now()
    return beforeToday && <p>Your lines will reset on {lineResetDate.format('MMMM Do, YYYY')}</p>
  }

  return (
    <AccountPageLayout>
      <LinesSection>
        <Avatar email={getCognitoEmailFromUser(currentUser)} fullName={getFullNameFromUser(currentUser)} initials={getInitialsFromUser(currentUser)} />
        <LineUsage linesAvailable={talkItAttributes.linesAvailable} linesUsed={talkItAttributes.linesUsed} />
        {!env.TIAA_IDS.includes(team.id) && nextInvoiceDate(timePeriod())}
      </LinesSection>
      <Section>
        <Divider/>
        <Column>
          <SubSection>
            <H3>Profile</H3>
            <Container outline>
              <B1>Full Name</B1>
              <P2 style={{ marginBottom: '1rem' }}>{getFullNameFromUser(currentUser)}</P2>
              {primaryUserEmail.email_address && (
                <React.Fragment>
                  <B1>Account Email</B1>
                  <P2 style={{ marginBottom: '1rem' }}>{primaryUserEmail.email_address}</P2>
                </React.Fragment>
              )}
              {primaryUserPhone.number && (
                <React.Fragment>
                  <B1>Primary Phone</B1>
                  <P2 style={{ marginBottom: '1rem' }}>{formatPhoneNumber(primaryUserPhone.number)}</P2>
                </React.Fragment>
              )}
            </Container>
          </SubSection>
          <SubSection>
            <H3>Security</H3>
            <Caption>Manage your account security by taking action below. If you need more help, contact us.</Caption>
            <ButtonGroup width="full">
              <Button data-testid="changePassword" onClick={() => openModal(<ChangePasswordModal/>)} inverse>Change password</Button>
              <Button data-testid="signOut" iconColor={colors.BLUE} iconName={mdiExitToApp} onClick={signOut} secondary>Sign out</Button>
            </ButtonGroup>
            <Version>v{packageJson.version}</Version>
          </SubSection>
        </Column>
        <Column>
          <SubSection>
            <H3>Dictation Hotline</H3>
            <Caption>If you are still not using our free TalkIt+ app, contact us and we can talk with you about alternatives, including our phone dictation hotline.</Caption>
            <Container actionButton={ <Button data-testid="add-phone-button" onClick={() => openModal(<RegisterPhoneModal phoneAddedHandler={fetchData}/>)}>Register a phone number</Button>} outline>
              <InfoPopup label="Dictator ID" message={text.INFO_TEXT.dictatorID}/>
              <DictatorID>{dictatorId}</DictatorID>
              <InfoPopup label="Registered phone numbers" message={text.INFO_TEXT.registeredNumber}/>
              {!loading && phoneNumbers && phoneNumbers.length > 0
                ? <PhoneList data-testid="phonelist">
                  {phoneNumbers.filter(phone => phone.is_primary).map((phone, index) => <ListItem key={index}><H3 noSpace>{formatPhoneNumber(phone.number)}</H3></ListItem>)}
                  {phoneNumbers.filter(phone => !phone.is_primary).map((phone, index) => <ListItem key={index} onClick={() => openModal(<RegisterPhoneModal id={phone.id} isEditing={true} phoneAddedHandler={fetchData} value={formatPhoneNumber(phone.number)}/>)}><H3 noSpace>{formatPhoneNumber(phone.number)}</H3></ListItem>)}
                </PhoneList>
                : null}
            </Container>
          </SubSection>
          <SubSection>
            <H3>TalkIt+ App</H3>
            <Caption>Make note taking simple with TalkIt+ available on your favorite devices.</Caption>
            <AppDownloadButtons>
              <a href='https://play.google.com/store/apps/details?id=com.mobileassistant.talkit&hl=en_US&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1' rel="noopener noreferrer" target="_blank"><img alt='Get it on Google Play' src={playStore}/></a>
              <a href='https://apps.apple.com/us/app/talk-it-by-mobile-assistant/id627301223#?platform=iphone' rel="noopener noreferrer" target="_blank"><img alt='Download on the App Store' src={appStore}/></a>
            </AppDownloadButtons>
          </SubSection>
        </Column>
      </Section>
    </AccountPageLayout>
  )
}

export default AccountOverview
