import React, { useState } from 'react'
import { ModalDescription } from '../Text'
import Input from 'shared/components/Input'
import { useAuthContext } from 'shared/context/AuthProvider'
import { isValidPhoneNumber, isValidForm, isValidField } from 'shared/validators'
import { useModalContext } from 'shared/context/ModalProvider'
import { useNotificationContext } from 'shared/context/NotificationProvider'
import Modal from '../Modal'
import { getPhoneNumberDigits, getCognitoId } from 'shared/extractors'
import LoadingSpinner from 'shared/components/LoadingSpinner'
import { DeleteIcon } from './index.styles'
import { mdiTrashCan } from '@mdi/js'
import { API } from 'aws-amplify'
import { colors } from 'shared/colors'
import { text } from 'shared/text'

const RegisterPhoneModal = (props) => {
  const { phoneAddedHandler, value, id } = props
  const initialFieldState = { phone: value || '' }
  const initialFieldErrorState = { phone: '' }
  const [field, setField] = useState(initialFieldState)
  const [fieldError, setFieldError] = useState(initialFieldErrorState)
  const [loading, setLoading] = useState(false)
  const { closeModal } = useModalContext()
  const { sendSuccessNotification, sendErrorNotification } = useNotificationContext()
  const { currentUser } = useAuthContext()
  const cognitoId = getCognitoId(currentUser)

  const updateField = (event) => {
    setField({ ...field, [event.target.name]: event.target.value })
  }

  const validateFieldOnKeyUp = (event) => {
    if (fieldError[event.target.name]) {
      validateField(event)
    }
  }

  const validateField = (event) => {
    isValidField({ event, setFieldError, fieldError, fieldErrorValidators })
  }

  const fieldErrorValidators = {
    phone: {
      error: 'Invalid phone number, must be 10 digits',
      validator: isValidPhoneNumber
    }
  }

  const addPhone = async () => {
    if (!loading && isValidForm(field, fieldError)) {
      try {
        setLoading(true)
        await API.post('TALK_IT_API', `/user_phone?cognito_id=${cognitoId}`, {
          body: {
            number: `+1${getPhoneNumberDigits(field.phone)}`
          }
        })
        triggerSuccess(text.USER_PHONE.CREATE.SUCCESS)
      } catch (error) {
        if (error.request && error.request.status === 409) {
          triggerFailure(text.USER_PHONE.CREATE.FAILED_ALREADY_REGISTERED)
        } else {
          triggerFailure(text.USER_PHONE.CREATE.FAILED)
        }
      }
    }
  }

  const updatePhone = async () => {
    if (!loading && isValidForm(field, fieldError)) {
      try {
        setLoading(true)
        await API.put('TALK_IT_API', `/user_phone/${id}?cognito_id=${cognitoId}`, {
          body: {
            number: `+1${getPhoneNumberDigits(field.phone)}`
          }
        })
        triggerSuccess(text.USER_PHONE.UPDATE.SUCCESS)
      } catch (error) {
        if (error.request && error.request.status === 409) {
          triggerFailure(text.USER_PHONE.UPDATE.FAILED_ALREADY_REGISTERED)
        } else {
          triggerFailure(text.USER_PHONE.UPDATE.FAILED)
        }
      }
    }
  }

  const deletePhone = async () => {
    if (!loading) {
      try {
        setLoading(true)
        await API.del('TALK_IT_API', `/user_phone/${id}?cognito_id=${cognitoId}`)
        triggerSuccess(text.USER_PHONE.DELETE.SUCCESS)
      } catch (error) {
        triggerFailure(text.USER_PHONE.DELETE.FAILED)
      }
    }
  }

  const triggerSuccess = (title) => {
    sendSuccessNotification({ title })
    if (phoneAddedHandler) phoneAddedHandler()
    closeModal()
  }

  const triggerFailure = (title) => {
    sendErrorNotification({ title })
    setLoading(false)
  }

  const modalDialogue = {
    primary: {
      onClick: props.isEditing ? updatePhone : addPhone,
      disabled: !isValidForm(field, fieldError) || loading,
      children: props.isEditing ? loading ? <LoadingSpinner /> : 'Save' : loading ? <LoadingSpinner /> : 'Add number'
    },
    secondary: {
      onClick: closeModal,
      disabled: loading
    }
  }

  return (
    <Modal dialogue={modalDialogue} onClose={closeModal} title={props.isEditing ? 'Registered Number' : 'Register a New Number'}>
      {!props.isEditing ? <ModalDescription>We do not register network phone numbers or phone numbers with extensions. Please enter a unique number.</ModalDescription> : null}
      <div style={{ width: '100%', display: 'flex' }}>
        <Input
          data-testid="phone-number"
          error={fieldError.phone}
          guide={false}
          label="Phone Number"
          mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
          name="phone"
          onBlur={validateField}
          onChange={updateField}
          onKeyUp={validateFieldOnKeyUp}
          placeholder="(555) 555-5555"
          type="tel"
          value={field.phone} />
        {props.isEditing && (
          <DeleteIcon
            color={colors.RED}
            data-testid="delete-icon"
            onClick={deletePhone}
            path={mdiTrashCan}
            size={1.5}/>
        )}
      </div>
    </Modal>
  )
}

export default RegisterPhoneModal
