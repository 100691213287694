import styled, { css } from 'styled-components'
import { colors } from 'shared/colors'
import { media } from 'shared/breakpoints'

export const StyledBanner = styled.div`
    height: 3rem;
    background-color: ${colors.BLACK};
    color: WHITE;
    display: flex;
    align-items: center;
    padding: 0 1rem;
    font-weight: bold;
    justify-content: space-between;
    width: 100%;

    ${media.print} {
        display: none;
    }

    ${props => props.inverse && css`
        color: ${colors.BLACK};
        background-color: ${colors.GREY_200};
    `}
`

export const Message = styled.div`
    display: flex;
    align-items: center;
`

export const Title = styled.div``

export const IonIconLeft = styled.div`
    display: flex;
    align-items: center;
    font-size: 24px;
    margin-right: 0.5rem;
`

export const IonIconRight = styled.div`
    display: flex;
    align-items: center;
    font-size: 24px;
    margin-left: 0.5rem;
`

export const Action = styled.div`
    a:hover {
        background-color: ${colors.TRANSPARENT};
    }
`
